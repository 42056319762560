<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A student starts with a
        <stemble-latex content="$6.00\,\text{M}$" />
        stock solution of
        <chemical-latex content="NaCl\text{.}" />
        <number-value :value="vol1" unit="\text{mL}" />
        of this stock solution is removed and diluted to
        <stemble-latex content="$50.00\,\text{mL.}$" />
        Then,
        <number-value :value="vol2" unit="\text{mL}" />
        of this new diluted solution is added to a new volumetric flask and diluted to
        <stemble-latex content="$100.00\,\text{mL.}$" />
        What is the final concentration, in
        <stemble-latex content="$\text{M,}$" />
        of
        <chemical-latex content="Na+(aq)" />
        after the two step serial dilution?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$[\ce{Na+}]:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import NumberValue from '../NumberValue';

export default {
  name: 'ChemMcMPL5Q9',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    vol1() {
      return this.taskState.getValueBySymbol('vol1').content;
    },
    vol2() {
      return this.taskState.getValueBySymbol('vol2').content;
    },
  },
};
</script>
